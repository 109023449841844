import React from 'react';
import styles from './ProjectCard.module.css';
import { useNavigate } from 'react-router-dom';

function ProjectCard({ imageSrc, title, description, alignImageLeft, backgroundColor, keywords, page}) {
    const navigate = useNavigate();

    const handleImageClick = () => {
        navigate('/projects/' + page);
    }
    
    const textBackground = backgroundColor === '#e63946' ? styles.redBackground : styles.whiteBackground;
    const containerStyle = alignImageLeft ? styles.cardContainer : `${styles.cardContainer} ${styles.reverse}`;

    return (
        <div className={containerStyle}>
            <img src={imageSrc} alt={title} className={styles.cardImage} onClick={handleImageClick}/>
            <div className={`${styles.cardText} ${textBackground}`}>
                <h2>{title}</h2>
                <p>{description}</p>
                <div style={{ marginTop: '10px' }}>
                    {keywords.map((keyword, index) => (
                        <span key={index} className={styles.keyword}>
                            {keyword}
                        </span>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ProjectCard;