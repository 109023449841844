import React from "react";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Home from "./pages/Home/Home";
import NotFound from "./pages/NotFound";
import { BrowserRouter as Router, Routes, Route, Switch, Navigate } from "react-router-dom";
import Projects from "./pages/Projects/Projects";
import ProjectsDetail from "./pages/ProjectsDetail";
import PetCrunch from "./pages/Details/PetCrunch/PetCrunch"
import Research from "./pages/Research";

const App = () => {
    return (
        <Router>
            <Navbar />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/projects/petcrunch" element={<PetCrunch />} />
                <Route path="/research" element={<Research />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
            <Footer />
        </Router>
    );
}

export default App;