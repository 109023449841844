import React from 'react';
import ProjectCard from './ProjectCard';
import iosImage from '../../img/PetCrunch.png'

const projects = [
    {
        imageSrc: iosImage,
        title: 'PetCrunch - Health Care App',
        description: 'Also "Why Vomiting/Diarrhea" App. PetCrunch is a mobile application designed to help pet owners keep track of their pets health and wellness. The app allows users to log their pets diets, whether it be wet food, dry food, freeze-dried food, raw food, or supplements. It also tracks the appropriate amount of each ingredient and sends reminders if adjustments are needed.',
        keywords: ['iOS','Healthcare', 'React Native', 'Node.js', 'MongoDB'],
        page: 'petcrunch'
    }
    // Add more projects as needed
];

function Projects() {
    const [selectedKeywords, setSelectedKeywords] = React.useState([]); 

    const handleKeywordClick = (keyword) => {
        if (selectedKeywords.includes(keyword)) {
            setSelectedKeywords(selectedKeywords.filter((selectedKeyword) => selectedKeyword !== keyword));
        } else {
            setSelectedKeywords([...selectedKeywords, keyword]);
        }
    };

    const filteredProjects = projects.filter((project) => 
        selectedKeywords.length === 0 || project.keywords.some(keyword => selectedKeywords.includes(keyword))
    );


    const allKeywords = [...new Set(projects.flatMap(project => project.keywords))];
    return (
        <div>
            <p>Please select keywords to filter the projects.</p>
            <div>
                {allKeywords.map((keyword, index) => (
                    <button key={index} onClick={() => handleKeywordClick(keyword)}>
                        {keyword}
                    </button>
                ))}
            </div>
            {filteredProjects.map((project, index) => (
                <ProjectCard
                    key={index}
                    imageSrc={project.imageSrc}
                    title={project.title}
                    description={project.description}
                    alignImageLeft={index % 2 === 0} // True for even index, false for odd index
                    backgroundColor={index % 2 === 0 ? '#e63946' : 'white'} // Red for even index, white for odd index
                    keywords={project.keywords}
                    page={project.page}
                />
            ))}
        </div>
    );
}

export default Projects;
