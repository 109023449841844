import React from 'react';
import { NavLink } from 'react-router-dom';
import { FaUserCircle, FaCode, FaBrain } from 'react-icons/fa';
import './Navbar.css'; // Create a CSS file for styling

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="left-placeholder"></div>
      <div className="title">
        <h1>Ava's Portfolio</h1>
      </div>
      <ul className="navWrapper">
        <li>
          <NavLink to="/" exact className="navLink" activeClassName="active">
            <FaUserCircle size={24} />
            <span>About Me</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/projects" className="navLink" activeClassName="active">
            <FaCode size={24} />
            <span>Projects</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/research" className="navLink" activeClassName="active">
            <FaBrain size={24} />
            <span>Research</span>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
