import React from 'react';
import img1 from '../../../img/mealList_15.png';
import img2 from '../../../img/selectFood_15.png';
import img3 from '../../../img/foodList_15.png';
import img4 from '../../../img/addFood_15.png';
import styles from './PetCrunch.module.css';

function PetCrunch() {
    const screenshots = [img1, img2, img3, img4];

    return (
        <div className={styles.screenshotsContainer}> 
            <div className={styles.pageHeader}>
                <h2>PetCrunch</h2>
                After graduating from my PhD program last year, 
                I faced two major concerns: finding a job and figuring out why my cat was throwing up. I needed an opportunity to work on real-life problems to develop my data skills and I felt bad that I couldn't recall what and when my cat had eaten during vet visits. I decided to address both problems with a single solution: founding a small startup and developing an app to monitor pet health and dietary needs. Thus, PetCrunch was born.
                <br></br>
                <br></br>
                The fundamental functions of PetCrunch are shown in the screenshots below. 
                The predictive modeling component is still under construction, 
                but the frontend code is available on my <a href="https://github.com/yuhuidu02/PetCrunch" target="_blank" rel="noopener noreferrer">GitHub</a>.
            </div>

            <div className={styles.stickyNote1}>
                After login, you will see the meal list page. 
                Each time you feed your pet (or caught your cat eating), you can add a meal record by clicking the "Add Meal" button.
            </div>
            <div className={styles.stickyNote2}>
                The Add Meal page allows you to select the food from your food list, 
                input the amount you gave, the amount your pet ate, and the time of the meal.
            </div>
            <div className={styles.stickyNote3}>
                Here is your food list. You can add new food by clicking the "Add Food" button.
            </div>
            <div className={styles.stickyNote4}>
            In addition to the energy the food provides, it's always good to keep track of the ingredients included. I used to use one that had a very low amount of omega-3. Also, you will receive a notification when the best-by date expires.
            </div>
            {screenshots.map((screenshot, index) => (
                <div key={index} className={styles.screenshotItem}>  
                <img src={screenshot} alt={`Screenshot ${index + 1}`} />
                </div>
            ))}
          
        </div>
    );
}

export default PetCrunch;
