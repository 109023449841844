import React from "react";
import './Home.css';
import headshots from '../../img/AD_2023_small.jpg'
import { AiFillLinkedin, AiFillGithub, AiFillTwitterCircle, AiFillInstagram } from 'react-icons/ai';
import { FiX } from 'react-icons/fi'; // Importing the 'X' icon from Feather Icons
import {FaXTwitter} from 'react-icons/fa6'

const Home = () => {
  return (
    <div className="about-me-container">
      <div className="profile-card">
        <img src={headshots} alt="headshots" className="profile-picture" />
        <h2>Yuhui (Ava) Du, Ph.D. </h2>
        <p>Full-Stack Data Scientist</p>
        <div className="social-icons">
          <a href="https://www.linkedin.com/in/yuhui-du-ph-d-982493121/" target="_blank" rel="noopener noreferrer">
            <AiFillLinkedin size={30} />
          </a>
          <a href="https://github.com/yuhuidu02?tab=repositories" target="_blank" rel="noopener noreferrer">
            <AiFillGithub size={30} />
          </a>
          <a href="https://x.com/yuhuidu" target="_blank" rel="noopener noreferrer">
            <FaXTwitter size={30} />
          </a>
          <a href="https://www.instagram.com/yuhui.du/" target="_blank" rel="noopener noreferrer">
            <AiFillInstagram size={30} />
          </a>
        </div>
      </div>
      <div className="about-me-text">
        <h1>I'm Ava</h1>
        <p>
        I'm a data scientist with a Ph.D. in Cognitive Psychology and a graduate minor in Computer Science. 
        My academic journey at The Ohio State University equipped me with a strong foundation in research, 
        data analysis, and machine learning. 
        I've published research findings and have hands-on experience in designing controlled online experiments, 
        managing large database, and implementing statistical and machine learning techniques. 
        My projects range from speech-to-text translation to real-time eye tracking, 
        demonstrating my proficiency in Python, R, and cloud computing. 
        I bring a unique blend of cognitive psychology insights and data science skills, 
        making me a valuable asset to any team, 
        dedicated to transforming data into actionable insights and driving innovation.
        </p>
        <button className="projects-button" onClick={() => window.location.href = '/projects'}>PROJECTS</button>
      </div>
    </div>
  );
};

export default Home;